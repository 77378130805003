<div class="profile-page">
    <div class="wrapper">
        <div class="page-header page-header-small" filter-color="green">
            <div class="page-header-image" data-parallax="true" style="background-image: url('assets/images/intro.jpg');">
            </div>
            <div class="container">
                <div class="content-center">
                    <div class="cc-profile-image">
                        <a href="#">
                            <img src="assets/images/mypic.png" alt="Image" />
                        </a>
                    </div>
                    <h1 class="h2 title">Maanan Bahl</h1>
                    <p class="category text-white">Software Developer</p>
                    <a class="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Hire Me</a>
                    <a class="btn btn-primary" href={{cvUrl}} target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Download CV</a>
                </div>
            </div>
            <div class="section">
                <div class="container">
                    <div class="button-container">
                        <!-- <a class="btn btn-default btn-round btn-lg btn-icon" href="https://www.facebook.com/mehul.kothari.39" target="_blank" rel="tooltip" title="Follow me on Facebook">
                            <i class="fa fa-facebook"></i>
                        </a> -->
                        <a class="btn btn-default btn-round btn-lg btn-icon" href="https://www.instagram.com/maanan_vlogways/" target="_blank" rel="tooltip" title="Follow me on Instagram">
                            <i class="fa fa-instagram"></i>
                        </a>
                        <a class="btn btn-default btn-round btn-lg btn-icon" href="https://www.linkedin.com/in/maanan-bahl/" target="_blank" rel="tooltip" title="Follow me on Linkedin">
                            <i class="fa fa-linkedin"></i>
                        </a>
                        <a class="btn btn-default btn-round btn-lg btn-icon" href="https://github.com/MaananBahl" target="_blank" rel="tooltip" title="Follow me on Github">
                            <i class="fa fa-github"></i>
                        </a>
                        <!-- <a class="btn btn-default btn-round btn-lg btn-icon" href="https://twitter.com/MehulKo18943279" target="_blank" rel="tooltip" title="Follow me on Twitter">
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a class="btn btn-default btn-round btn-lg btn-icon" href="#" target="_blank" rel="tooltip" title="Follow me on Google+">
                            <i class="fa fa-google-plus"></i>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>