<div class="section" id="reference">
        <div class="container cc-reference">
          <div class="h4 mb-4 text-center title">References</div>
          <div class="card" data-aos="zoom-in">
            <div class="carousel slide" id="cc-Indicators" data-ride="carousel">
              <ol class="carousel-indicators">
                <li class="active" data-target="#cc-Indicators" data-slide-to="0"></li>
                <li data-target="#cc-Indicators" data-slide-to="1"></li>
               
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-lg-2 col-md-3 cc-reference-header">
                      <img src="assets/images/sg.jpg" alt="Image" />
                     
   
                    </div>
                    <!-- <div class="col-lg-10 col-md-9">
                    <p>
                      Mehul is creative, innovative, responsible and quite a rational thinker. 
                    Coding as a way of life, his work presents products with great quality, following standards
                     and good practices.He completed one of my live project on matrimonial website and 
                     I am so impressed with his techniques.
                    </p>
                    <p>He has always been hardworking ,creaive,and absolute genius in his creative criticial thinking 
                      He is always ready to take challege as his new opprotunities.
                    </p>

                    <div class="h5 mt-1">Sagar Patel</div>
                    <p class="category">HOD, at ASOIT </p>
                    </div> -->
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-2 col-md-3 cc-reference-header">
                      <img src="assets/images/hn.jpg" alt="Image" />
                     
                    </div>
                    <!-- <div class="col-lg-10 col-md-9">
                      <p> Mehul is a really good working partner with high knowledge on backend and frontend development , 
                        he is always concerned with going forward never afraid of improving or making use of new technologies, 
                        helping his peers whenever
                         he is able to and solving problems in a quick and efficient manner with his typical optimism</p>

                         <p>We have worked two years togather in same project ,so i know him from my personal experience
                           Every person want this guy to be in his team.And as a persona I m sure company wont regret for hiring him
                         </p>

                         <div class="h5 mt-1">Hetal </div>
                         <p class="category">S/W Developer, at Sygnet</p>
                    </div> -->
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>