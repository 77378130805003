<footer class="footer">
    <div class="container text-center">
      <!-- <a class="cc-facebook btn btn-link" href="https://www.facebook.com/mehul.kothari.39" target="_blank">
        <i class="fa fa-facebook fa-2x " aria-hidden="true"></i>
      </a> -->
      <a class="cc-instagram btn btn-link" href="https://www.instagram.com/maanan_vlogways/" target="_blank">
        <i class="fa fa-instagram fa-2x " aria-hidden="true"></i>
      </a>
      <a class="cc-twitter btn btn-link " href="https://www.linkedin.com/in/maanan-bahl/" target="_blank">
        <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>
      </a>

      <a class="cc-github btn btn-link " href="https://github.com/MaananBahl" target="_blank">
        <i class="fa fa-github fa-2x " aria-hidden="true"></i>
      </a>
      <!-- <a class="cc-google-plus btn btn-link" href="#" target="_blank">
        <i class="fa fa-google-plus fa-2x" aria-hidden="true"></i>
      </a> -->
    </div>
    <div class="text-center text-muted">
      <p>&copy; All rights reserved.
      </p>
    </div>
  </footer>