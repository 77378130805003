<div class="section" id="education">
    <div class="container cc-education">
      <div class="h4 text-center mb-4 title">Education</div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2014 - 2018</p>
              <div class="h5">Bachelor's Degree</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">Bachelor of Technology</div>
               <h4 class="category">KALINGA INSTITUTE OF INDUSTRIAL TECHNOLOGY, BHUBANESWAR
               </h4>
               Completed my B.Tech in Computer Science & Engineering with <b>7.74 GPA</b>.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2013 - 2014</p>
              <div class="h5">Higher Secondary</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">Higher Secondary</div>
               <h4 class="category">Ryan International School, Ghaziabad
               </h4>
               Completed my high school in Science stream (Physics, Chemistry & Maths) with<b> 82%</b>.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row">
          <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body cc-education-header">
              <p>2011 - 2012</p>
              <div class="h5">Secondary School</div>
            </div>
          </div>
          <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
            <div class="card-body">
              <div class="h5">Secondary School</div>
               <h4 class="category">Ryan International School, Ghaziabad
               </h4>
               Completed my Secondary school with <b>90%</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>