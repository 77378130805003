<div class="section" id="portfolio">
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="h4 text-center mb-4 title">Portfolio</div>
          <div class="nav-align-center">
            <ul class="nav nav-pills nav-pills-primary" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#web-development" role="tablist">
                  <i class="fa fa-laptop" aria-hidden="true"></i>
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-content gallery mt-5">
        <div class="tab-pane active" id="web-development">
          <div class="ml-auto mr-auto">
            <div class="row">
              <div class="col-md-6">
                <div class="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                  <a href="https://personal-portfolio0320.000webhostapp.com/">
                    <figure class="cc-effect">
                      <img src="assets/images/portfolio1.PNG" alt="Image" />
                      <figcaption>
                        <div class="h4">Made Using Angular8</div>
                        <p>Web Development</p>
                      </figcaption>
                    </figure>
                  </a>
                </div>
              </div>
              <div class="col-md-6">
                <div class="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                  <a href="http://msgc320.pythonanywhere.com/">
                    <figure class="cc-effect">
                      <img src="assets/images/portfolio2.PNG" alt="Image" />
                      <figcaption>
                        <div class="h4">Made Using Django</div>
                        <p>Web Development</p>
                      </figcaption>
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>