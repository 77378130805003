<app-header></app-header>
<div class="page-content">
    <app-intro></app-intro>
    <app-about></app-about>
    <app-experience></app-experience>
    <!-- <app-projects></app-projects> -->
    <app-skills></app-skills>
    <app-education></app-education>

    <!-- <app-portfolio></app-portfolio> -->
    <!-- <app-reference></app-reference> -->
    <app-contact></app-contact>
</div>
<app-footer></app-footer>
<!-- <ngx-spinner bdColor="rgba(255,255,255,1)" size="medium" color="#033d5b" type="ball-spin-clockwise"></ngx-spinner> -->